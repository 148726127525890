import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Content, { HTMLContent } from '../components/Content';
import '../styles/global.css';

export const OfferingPostTemplate = ({
  content,
  contentComponent,
  title,
  helmet,
}) => {
  const PostContent = contentComponent || Content;

  return (
    <section className="section">
      {helmet || ''}
      <h2
        className="has-text-weight-bold is-size-1"
        style={{
          backgroundColor: '#212529',
          color: '#212529',
          padding: '1rem',
        }}
      >
        Offering
      </h2>
      <div className="container content" style={{ padding: '4rem 0' }}>
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <h2 className="title is-size-2 has-text-weight-bold is-bold-light">
              {title}zzzzzzz
            </h2>
            <PostContent content={content} />
          </div>
        </div>
      </div>
    </section>
  );
};

OfferingPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  title: PropTypes.string,
  helmet: PropTypes.object,
};

const OfferingPost = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <OfferingPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        helmet={
          <Helmet titleTemplate="%s | Offering">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.offeringdescription}`}
            />
          </Helmet>
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
      />
    </Layout>
  );
};

OfferingPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default OfferingPost;

export const pageQuery = graphql`
  query OfferingPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        offeringdescription
        tags
      }
    }
  }
`;
